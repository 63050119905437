import React, { useState, useEffect } from "react";
import {
  Dropdown,
  Form,
  Grid,
  Header,
  Input,
  Segment,
} from "semantic-ui-react";
import {
  ASSISTANCE_AUTO_ENUMS,
  BRIS_DE_GLACES_ENUMS,
  GARANTIES_DOMMAGE_VEHICULE_ENUMS,
  GARANTIES_INCENDIE_ENUMS,
  GARANTIES_VOL_ENUMS,
  INDIVIDUELLE_ACCIDENT_CONDUCTEUR,
  RESPONSABILITE_CIVILE,
} from "../../../../utils/constants";
import {
  calculateFranchiseDommageVehicule,
  changeBrisDeGlaceValue,
  handleAvariesFacultativesRisqueChange,
  handleIncendieRisqueChange,
  handleVolRisqueChange,
  updateGaranties,
} from "../../../../utils/souscription_helper";
import dayjs from "dayjs";

const Garantie = ({
  garantie,
  index,
  formik,
  directivesTarifairesValues,
  companyDirectiveValues,
  avariesFacultatives,
}) => {
  const { values, setFieldValue } = formik;
  const [franchiseChecked, setFranchiseChecked] = useState(false);

  const handleRisqueChange = async (risque) => {
    const ageDuVehicule = dayjs().diff(
      formik.values.identite_vehicule.date_premiere_mise_en_circulation,
      "year"
    );
    const updatedGaranties = [...formik.values.garanties];
    const updatedRisque = {
      ...risque,
      //value: 0,
      checked: !risque.checked,
    };
    updatedGaranties[index] = {
      ...garantie,
      risques: garantie.risques.map((r) =>
        r.uuid === risque.uuid ? updatedRisque : r
      ),
    };
    if (
      risque.libelle ===
        GARANTIES_DOMMAGE_VEHICULE_ENUMS.FRANCHISE_DOMMAGE_VEHICULE &&
      !risque.checked
    ) {
      setFieldValue(
        "conditions_particulieres.pool_avaries_facultatives_uuid",
        0
      );
    }
    if (garantie.libelle === GARANTIES_VOL_ENUMS.VOL) {
      return await handleVolRisqueChange(
        updatedGaranties,
        index,
        updatedRisque,
        formik.values,
        formik.setFieldValue
      );
    }
    if (garantie.libelle === GARANTIES_INCENDIE_ENUMS.INCENDIE) {
      return await handleIncendieRisqueChange(
        ageDuVehicule > 2
          ? formik.values.identite_vehicule.valeur_venale
          : formik.values.identite_vehicule.valeur_a_neuf,
        formik.values.identite_vehicule.date_premiere_mise_en_circulation,
        updatedGaranties,
        index,
        updatedRisque,
        formik.values,
        formik.setFieldValue
      );
    }
    if (garantie.libelle === BRIS_DE_GLACES_ENUMS.BRIS_DE_GLACES) {
      return changeBrisDeGlaceValue(
        formik.values.identite_vehicule.valeur_a_neuf,
        directivesTarifairesValues.find(
          (item) => item.name === "tarif_bris_glaces"
        )?.value,
        values,
        formik.setFieldValue
      );
    }
    if (
      garantie.libelle ===
      GARANTIES_DOMMAGE_VEHICULE_ENUMS.GARANTIES_DOMMAGE_VEHICULE
    ) {
      return handleAvariesFacultativesRisqueChange(
        updatedGaranties,
        index,
        updatedRisque,
        formik.values,
        formik.setFieldValue
      );
    }
    formik.setFieldValue("garanties", updatedGaranties, true);
  };

  return (
    <Grid.Column key={garantie.uuid}>
      <Header as="h6" block>
        {garantie.libelle}
      </Header>
      <div style={{ paddingLeft: "15px" }}>
        {garantie.risques.map((risque) => (
          <>
            {risque.libelle !== "Franchise" && (
              <Form.Checkbox
                style={{ fontSize: "12px" }}
                key={risque.uuid}
                label={`${risque.libelle} ${
                  garantie.is_required
                    ? ""
                    : `: ${new Intl.NumberFormat().format(
                        isNaN(Number(risque.value)) ? 0 : Number(risque.value)
                      )}`
                }`}
                checked={risque.checked}
                readOnly={garantie.is_required}
                onChange={() => handleRisqueChange(risque)}
              />
            )}
            {risque.libelle === "Franchise" && (
              <div>
                <Form.Checkbox
                  label="Dommage au véhicule"
                  checked={risque.checked}
                  onChange={(e, data) => {
                    const primeDommage = calculateFranchiseDommageVehicule(
                      formik.values.identite_vehicule.valeur_a_neuf,
                      values.conditions_particulieres
                        .pool_avaries_facultatives_uuid,
                      companyDirectiveValues,
                      avariesFacultatives
                    );
                    if (data.checked) {
                      risque.value = primeDommage;
                    } else {
                      risque.value = 0;
                      setFieldValue(
                        "conditions_particulieres.pool_avaries_facultatives_uuid",
                        0
                      );
                    }
                    handleRisqueChange(risque);
                  }}
                />
                {risque.checked && (
                  <>
                    <div>
                      <label>
                        Franchise :{" "}
                        {new Intl.NumberFormat().format(
                          avariesFacultatives.find(
                            (avary) =>
                              avary.uuid ===
                              values.conditions_particulieres
                                .pool_avaries_facultatives_uuid
                          )?.minimum || null
                        )}
                      </label>
                    </div>
                    <Dropdown
                      options={[
                        {
                          key: "0",
                          text: "Choisir...",
                          value: 0,
                        },
                        ...avariesFacultatives.map((avary) => ({
                          key: avary.uuid,
                          text: `${avary.pourcentage_montant_sinistre}%`,
                          value: avary.uuid,
                        })),
                      ]}
                      value={
                        values.conditions_particulieres
                          .pool_avaries_facultatives_uuid
                          ? values.conditions_particulieres
                              .pool_avaries_facultatives_uuid
                          : 0
                      }
                      onChange={(e, data) => {
                        try {
                          const value = data.value;
                          if (value === 0) {
                            setFieldValue(
                              "conditions_particulieres.pool_avaries_facultatives_uuid",
                              0
                            );
                            const updatedGaranties = [
                              ...formik.values.garanties,
                            ];
                            const updatedRisque = {
                              ...risque,
                              value: 0,
                              checked: false,
                            };
                            updatedGaranties[index] = {
                              ...garantie,
                              risques: garantie.risques.map((r) =>
                                r.uuid === risque.uuid ? updatedRisque : r
                              ),
                            };
                            formik.setFieldValue(
                              "garanties",
                              updatedGaranties,
                              true
                            );
                          } else {
                            const primeDommage =
                              calculateFranchiseDommageVehicule(
                                formik.values.identite_vehicule.valeur_a_neuf,
                                value,
                                companyDirectiveValues,
                                avariesFacultatives
                              );
                            const updatedGaranties = [
                              ...formik.values.garanties,
                            ];
                            const updatedRisque = {
                              ...risque,
                              value: primeDommage,
                            };
                            updatedGaranties[index] = {
                              ...garantie,
                              risques: garantie.risques.map((r) =>
                                r.uuid === risque.uuid ? updatedRisque : r
                              ),
                            };
                            formik.setFieldValue(
                              "garanties",
                              updatedGaranties,
                              true
                            );
                            setFieldValue(
                              "conditions_particulieres.pool_avaries_facultatives_uuid",
                              data.value
                            );
                          }
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    />
                  </>
                )}
              </div>
            )}
            {risque.libelle === ASSISTANCE_AUTO_ENUMS.ASSISTANCE_AUTO &&
              risque.checked && (
                <div className="ml-6">
                  <Input
                    size="mini"
                    type="number"
                    value={
                      values.garanties
                        .find((g) => g.libelle === garantie.libelle)
                        .risques.find((r) => r.libelle === risque.libelle).value
                    }
                    onChange={(e, data) => {
                      const value = data.value;
                      const updatedGaranties = [...formik.values.garanties];
                      const updatedRisque = {
                        ...risque,
                        value: value,
                      };
                      updatedGaranties[index] = {
                        ...garantie,
                        risques: garantie.risques.map((r) =>
                          r.uuid === risque.uuid ? updatedRisque : r
                        ),
                      };
                      formik.setFieldValue("garanties", updatedGaranties, true);
                    }}
                  />
                </div>
              )}
          </>
        ))}
      </div>
      <div
        className="mt-3 py-1 px-3 bg-slate-200 rounded-md font-bold"
        key={garantie.uuid}
      >
        <div className="flex justify-between">
          <div>Prime nette</div>
          <div>
            {[INDIVIDUELLE_ACCIDENT_CONDUCTEUR, RESPONSABILITE_CIVILE].includes(
              garantie.libelle
            ) ? (
              <>
                {garantie.libelle === INDIVIDUELLE_ACCIDENT_CONDUCTEUR
                  ? new Intl.NumberFormat().format(
                      directivesTarifairesValues.find(
                        (item) =>
                          item.name === "prime_nette_individuelle_conducteur"
                      )?.value
                    )
                  : new Intl.NumberFormat().format(values.prime_nette)}
              </>
            ) : (
              <>
                {garantie.libelle ===
                  GARANTIES_DOMMAGE_VEHICULE_ENUMS.GARANTIES_DOMMAGE_VEHICULE &&
                  garantie.risques.find(
                    (risque) => risque.libelle === "Franchise"
                  )?.checked &&
                  garantie.risques.find(
                    (risque) => risque.libelle === "Franchise"
                  )?.value === 0 && (
                    <div>
                      {new Intl.NumberFormat().format(
                        (companyDirectiveValues.find(
                          (item) => item.name === "taux_prime_dommage"
                        )?.value *
                          values.identite_vehicule.valeur_a_neuf) /
                          100
                      )}
                    </div>
                  )}
                {garantie.risques.reduce(
                  (acc, risque) => acc + Number(risque.value),
                  0
                ) > 0 && (
                  <div>
                    {new Intl.NumberFormat().format(
                      garantie.risques.reduce(
                        (acc, risque) => acc + Number(risque.value),
                        0
                      )
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Grid.Column>
  );
};

const GarantiesSection = ({
  formik,
  directivesTarifairesValues,
  companyDirectiveValues,
  avariesFacultatives,
}) => {
  return (
    <Segment>
      <Header content="Tableau des garanties" dividing />
      <Grid columns={4}>
        {formik.values.garanties?.map((garantie, index) => (
          <Garantie
            key={garantie.uuid}
            garantie={garantie}
            index={index}
            formik={formik}
            directivesTarifairesValues={directivesTarifairesValues}
            companyDirectiveValues={companyDirectiveValues}
            avariesFacultatives={avariesFacultatives}
          />
        ))}
      </Grid>
    </Segment>
  );
};

export default GarantiesSection;
