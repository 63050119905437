import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown, Icon, Pagination, Segment, Table } from "semantic-ui-react";
import Swal from "sweetalert2";
import * as policesService from "../../services/gestion_production/policesService";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useRecoilValue } from "recoil";
import { authState } from "../../atoms/authState";
import { USERS_ROLE_ENUMS } from "../../constants";

function PolicesList() {
  const [souscriptions, setSouscriptions] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    nextPage: null,
    previousPage: null,
    limit: 10,
  });
  const userData = useRecoilValue(authState);
  const navigate = useNavigate();

  const fetchSouscriptions = async () => {
    try {
      const response = await policesService.findAll();
      if (
        response.status === 200 &&
        Array.isArray(response.data?.souscriptions)
      ) {
        setSouscriptions(response.data.souscriptions);
        setPagination(response.data.pagination);
      }
    } catch (error) {
      toast.error("Erreur lors de la récupération des souscriptions");
    }
  };

  useEffect(() => {
    fetchSouscriptions();
  }, [pagination.currentPage]);

  const handleDeleteSouscription = async (uuid) => {
    Swal.fire({
      title: "Suppression",
      text: "Voulez-vous vraiment supprimer cette souscription ? Cette action est irréversible.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer !",
      cancelButtonText: "Annuler",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await policesService.deleteSouscription(uuid);
          if (response.status === 200) {
            fetchSouscriptions();
            toast.success("Souscription supprimée avec succès");
          }
        } catch (error) {
          toast.error(
            "Une erreur est survenue lors de la suppression de la souscription"
          );
        }
      }
    });
  };

  return (
    <Segment>
      <Table striped celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell collapsing>#</Table.HeaderCell>

            {[USERS_ROLE_ENUMS.POOL_USER, USERS_ROLE_ENUMS.ROOT_ADMIN].includes(
              userData?.user?.user_role
            ) && <Table.HeaderCell>Compagnie</Table.HeaderCell>}
            {[
              USERS_ROLE_ENUMS.COMPANY_USER,
              USERS_ROLE_ENUMS.COURTIER_USER,
            ].includes(userData?.user?.user_role) && (
              <Table.HeaderCell>Bureau</Table.HeaderCell>
            )}

            <Table.HeaderCell>Proposition</Table.HeaderCell>
            <Table.HeaderCell>Police</Table.HeaderCell>
            <Table.HeaderCell>N° Attestation</Table.HeaderCell>
            <Table.HeaderCell>Immatriculation</Table.HeaderCell>
            <Table.HeaderCell>Code client</Table.HeaderCell>
            <Table.HeaderCell>Nom Client</Table.HeaderCell>
            <Table.HeaderCell>Téléphone</Table.HeaderCell>
            <Table.HeaderCell>Création</Table.HeaderCell>
            <Table.HeaderCell textAlign="right" collapsing>
              Actions
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {souscriptions?.map((souscription, index) => (
            <Table.Row key={`${souscription.idpolices}`}>
              <Table.Cell>{index + 1}</Table.Cell>
              {[
                USERS_ROLE_ENUMS.POOL_USER,
                USERS_ROLE_ENUMS.ROOT_ADMIN,
              ].includes(userData?.user?.user_role) && (
                <Table.Cell>
                  {souscription.souscription_origine?.compagnies.denomination}
                </Table.Cell>
              )}
              {[
                USERS_ROLE_ENUMS.COMPANY_USER,
                USERS_ROLE_ENUMS.COURTIER_USER,
              ].includes(userData?.user?.user_role) && (
                <Table.Cell>
                  {
                    souscription.souscription_origine?.compagnies_agencies
                      .denomination
                  }
                </Table.Cell>
              )}
              <Table.Cell>
                {souscription.numero_proposition || "N/A"}
              </Table.Cell>
              <Table.Cell>
                {souscription.souscription_polices?.numero_police || "N/A"}
              </Table.Cell>
              <Table.Cell>
                {souscription.souscription_polices?.numero_attestation || "N/A"}
              </Table.Cell>
              <Table.Cell>
                {souscription.souscription_identite_vehicule
                  ?.immatriculation_vehicule || "N/A"}
              </Table.Cell>
              <Table.Cell>{souscription.souscripteurs?.num}</Table.Cell>
              <Table.Cell>
                {souscription.souscripteurs.groupe_souscripteur ===
                "PARTICULIER"
                  ? `${souscription.souscripteurs.last_name}
                 ${souscription.souscripteurs.first_name}`
                  : souscription.souscripteurs.denomination}
              </Table.Cell>
              <Table.Cell>{souscription.souscripteurs.phone}</Table.Cell>
              <Table.Cell>
                {dayjs(souscription.created_at).format("DD-MM-YYYY")}
              </Table.Cell>
              <Table.Cell textAlign="right">
                <Dropdown
                  pointing="right"
                  icon={<Icon name="ellipsis vertical" />}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      icon={<Icon name="eye" />}
                      content="Détails"
                      onClick={() =>
                        navigate(
                          `details/${souscription?.souscription_polices?.uuid}`
                        )
                      }
                    />
                    <Dropdown.Item
                      icon={<Icon name="trash" />}
                      content="Supprimer"
                      onClick={() =>
                        handleDeleteSouscription(souscription?.uuid)
                      }
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell
              colSpan={
                [
                  USERS_ROLE_ENUMS.POOL_USER,
                  USERS_ROLE_ENUMS.ROOT_ADMIN,
                  USERS_ROLE_ENUMS.COMPANY_USER,
                  USERS_ROLE_ENUMS.COURTIER_USER,
                ].includes(userData?.user?.user_role)
                  ? 11
                  : 10
              }
              textAlign="right"
            >
              <Pagination
                totalPages={pagination.totalPages}
                currentPage={pagination.currentPage}
                onPageChange={(page) =>
                  setPagination({ ...pagination, currentPage: page })
                }
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Segment>
  );
}

export default PolicesList;
