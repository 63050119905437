import { api } from "../axios/api";

export const fetchAll = async ({
  page = 1,
  limit = 10,
  search = "",
  user_type = "",
  all = false,
}) => {
  return api.get(`users`, {
    params: {
      page,
      limit,
      search: typeof search === "string" ? search : "",
      user_type: typeof user_type === "string" ? user_type : "",
      ...(all && { all: true }),
    },
  });
};

export const fetchOne = async (id) => {
  return api.get(`users/${id}`);
};
export const create = async (data) => {
  return api.post(`users`, data);
};

export const update = async (data, id) => {
  return api.put(`users/${id}`, data);
};

export const createAgentGeneral = async (data) => {
  return api.post(`users/agent-general`, data);
};

export const fetchAllByAgentGeneral = async (agent_general_uuid) => {
  return api.get(`users/agent-general/${agent_general_uuid}`);
};
