import { Text, View, StyleSheet } from "@react-pdf/renderer";
import numeral from "numeral";
import React from "react";
import { GARANTIES_DOMMAGE_VEHICULE_ENUMS } from "../../../utils/constants";

const GARANTIES_AUTOMOBILE_ENUMS = [
  "Responsabilité civile (RC)",
  "Garantie dommage au véhicule",
  "Bris de glaces",
  "Vol",
  "Incendie",
];

const GARANTIES_INDIVIDUELLES_CHAUFFEUR_ENUMS = [
  "Individuelle accident conducteur (IAC)",
];

const GARANTIES_ASSISTANCE_ENUMS = ["Assistance automobile"];

const NEANT = "NEANT";

const PRIMES_ANNUELLES_IAC = [
  {
    name: "Décès",
    value: 9300,
  },
  {
    name: "Frais médicaux",
    value: 7500,
  },
  {
    name: "Incapacité Totale et Partielle",
    value: 9300,
  },
];

const GarantieSectionHeader = ({ title }) => (
  <View style={[styles.tableRow, { backgroundColor: "#dce1e6" }]}>
    <Text
      style={[
        styles.tableCellGarantie,
        {
          fontWeight: "ultrabold",
          textAlign: "left",
        },
      ]}
    >
      {title}
    </Text>
    <Text style={styles.tableCell}></Text>
    <Text style={styles.tableCell}></Text>
    <Text style={styles.tableCell}></Text>
    <Text style={styles.tableCell}></Text>
    <Text style={styles.tableCellReduction}></Text>
    <Text style={styles.tableCellReduction}></Text>
    <Text style={styles.tableCellReduction}></Text>
    <Text style={styles.tableCell}></Text>
  </View>
);

function ConditionsParticulieresGaranties({ souscription }) {
  return (
    <>
      <View style={{ borderBottom: 1, paddingBottom: 4 }}>
        <Text
          style={{
            fontWeight: "bold",
            textAlign: "center",
            backgroundColor: "#dce1e6",
            padding: 4,
            textTransform: "uppercase",
            marginBottom: 4,
          }}
        >
          Garanties souscrites et capitaux assurés (en FCFA)
        </Text>
        <View style={styles.table}>
          {/* Table Header: First Row */}
          <View style={[styles.tableRow]}>
            <Text style={[styles.tableCellGarantie, styles.headerCell, { textAlign: "center" }]}>
              Garantie
            </Text>
            <Text style={[styles.tableCell, styles.headerCell, { textAlign: "center" }]}>Capital</Text>
            <Text style={[styles.tableCell, styles.headerCell, { textAlign: "center" }]}>Franchise</Text>
            <Text style={[styles.tableCell, styles.headerCell, { textAlign: "center" }]}>
              Prime {`\n`} Annuelle
            </Text>
            <Text style={[styles.tableCell, styles.headerCell, { textAlign: "center" }]}>
              Prime {`\n`} Nette
            </Text>
            <Text style={[styles.reductionMajorationHeader, { textAlign: "center" }]}>
              Réduction/Majoration
            </Text>
            <Text style={[styles.tableCell, styles.headerCell, { textAlign: "center" }]}>
              Prime {`\n`} Comptant
            </Text>
          </View>

          {/* Table Header: Second Row (for BNS, MLS, FLT) */}
          <View style={styles.tableRow}>
            <Text style={styles.tableCellGarantie}></Text>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCellReduction}>BNS</Text>
            <Text style={styles.tableCellReduction}>MLS</Text>
            <Text style={styles.tableCellReduction}>FLT</Text>
            <Text style={styles.tableCell}></Text>
          </View>

          <GarantieSectionHeader title="Automobile" />

          {souscription.souscription_garanties.map((souscription_garantie) => {
            if (
              !GARANTIES_AUTOMOBILE_ENUMS.includes(
                souscription_garantie.company_has_liste_garanties
                  .pool_directives_tarifaires_has_listes_garanties
                  .liste_garanties.name
              )
            )
              return null;

            return (
              <View style={styles.tableRow} key={souscription_garantie.uuid}>
                <Text style={[styles.tableCellGarantie, { paddingLeft: 8 }]}>
                  {
                    souscription_garantie.company_has_liste_garanties
                      .pool_directives_tarifaires_has_listes_garanties
                      .liste_garanties.name
                  }
                </Text>
                <Text style={styles.tableCell}>
                {
                    souscription_garantie.company_has_liste_garanties
                      .pool_directives_tarifaires_has_listes_garanties
                      .liste_garanties.name === "Responsabilité civile (RC)"
                      ? numeral(
                          souscription_garantie.company_has_liste_garanties
                            .pool_directives_tarifaires_has_listes_garanties
                            .liste_garanties.capitaux_garanties
                        ).format()
                      : null
                  }
                </Text>
                <Text style={styles.tableCell}>
                  {souscription_garantie.company_has_liste_garanties
                    .pool_directives_tarifaires_has_listes_garanties
                    .liste_garanties.name !==
                  GARANTIES_DOMMAGE_VEHICULE_ENUMS.GARANTIES_DOMMAGE_VEHICULE
                    ? NEANT
                    : (souscription?.conditions_particulieres
                        ?.pool_avaries_facultatives?.reduction || 0) + "%"}
                </Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}>
                  {souscription_garantie.company_has_liste_garanties
                    .pool_directives_tarifaires_has_listes_garanties
                    .liste_garanties.name === "Responsabilité civile (RC)"
                    ? numeral(souscription.prime_nette || 0).format()
                    : null}
                  {souscription_garantie.company_has_liste_garanties
                    .pool_directives_tarifaires_has_listes_garanties
                    .liste_garanties.name !== "Responsabilité civile (RC)"
                    ? numeral(
                        souscription_garantie.souscription_garanties_risques.reduce(
                          (acc, risque) => acc + Number(risque.value),
                          0
                        ) || 0
                      ).format()
                    : null}
                </Text>
                <Text style={styles.tableCellReduction}>0</Text>
                <Text style={styles.tableCellReduction}>0</Text>
                <Text style={styles.tableCellReduction}>0</Text>
                <Text style={styles.tableCell}>
                  {souscription_garantie.company_has_liste_garanties
                    .pool_directives_tarifaires_has_listes_garanties
                    .liste_garanties.name === "Responsabilité civile (RC)"
                    ? numeral(souscription.prime_nette || 0).format()
                    : null}
                  {souscription_garantie.company_has_liste_garanties
                    .pool_directives_tarifaires_has_listes_garanties
                    .liste_garanties.name !== "Responsabilité civile (RC)"
                    ? numeral(
                        souscription_garantie.souscription_garanties_risques.reduce(
                          (acc, risque) => acc + Number(risque.value),
                          0
                        ) || 0
                      ).format()
                    : null}
                </Text>
              </View>
            );
          })}

          <View style={styles.tableRow}>
            <Text
              style={[
                styles.tableCellGarantie,
                { fontWeight: "ultrabold", textAlign: "left" },
              ]}
            >
              Sous-Total Automobile
            </Text>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}>
              {numeral(
                souscription.souscription_garanties
                  .filter((garantie) =>
                    GARANTIES_AUTOMOBILE_ENUMS.includes(
                      garantie.company_has_liste_garanties
                        .pool_directives_tarifaires_has_listes_garanties
                        .liste_garanties.name
                    )
                  )
                  .reduce(
                    (total, garantie) =>
                      total +
                      garantie.souscription_garanties_risques.reduce(
                        (acc, risque) => acc + Number(risque.value),
                        0
                      ),
                    0
                  ) + Number(souscription.prime_nette)
              ).format()}
            </Text>
            <Text style={styles.tableCellReduction}></Text>
            <Text style={styles.tableCellReduction}></Text>
            <Text style={styles.tableCellReduction}></Text>
            <Text style={styles.tableCell}>
              {numeral(
                souscription.souscription_garanties
                  .filter((garantie) =>
                    GARANTIES_AUTOMOBILE_ENUMS.includes(
                      garantie.company_has_liste_garanties
                        .pool_directives_tarifaires_has_listes_garanties
                        .liste_garanties.name
                    )
                  )
                  .reduce(
                    (total, garantie) =>
                      total +
                      garantie.souscription_garanties_risques.reduce(
                        (acc, risque) => acc + Number(risque.value),
                        0
                      ),
                    0
                  ) + Number(souscription.prime_nette)
              ).format()}
            </Text>
          </View>

          <GarantieSectionHeader title="Individuelle Chauffeur" />

          {souscription.souscription_garanties.map((souscription_garantie) => {
            if (
              !GARANTIES_INDIVIDUELLES_CHAUFFEUR_ENUMS.includes(
                souscription_garantie.company_has_liste_garanties
                  .pool_directives_tarifaires_has_listes_garanties
                  .liste_garanties.name
              )
            )
              return null;

            return souscription_garantie.souscription_garanties_risques?.map(
              (risque) => {
                return (
                  <View style={styles.tableRow} key={risque.uuid}>
                    <Text
                      style={[styles.tableCellGarantie, { paddingLeft: 8 }]}
                    >
                      {risque.liste_garanties_risques.name}
                    </Text>
                    <Text style={styles.tableCell}>
                      {numeral(
                        risque.liste_garanties_risques?.capitaux_garanties || 0
                      ).format()}
                    </Text>
                    <Text style={styles.tableCell}>{NEANT}</Text>
                    <Text style={styles.tableCell}>
                      {numeral(
                        PRIMES_ANNUELLES_IAC.find(
                          (prime) =>
                            prime.name === risque.liste_garanties_risques?.name
                        )?.value || 0
                      ).format()}
                    </Text>
                    <Text style={styles.tableCell}>
                      {numeral(
                        PRIMES_ANNUELLES_IAC.find(
                          (prime) =>
                            prime.name === risque.liste_garanties_risques?.name
                        )?.value || 0
                      ).format()}
                    </Text>
                    <Text style={styles.tableCellReduction}>0</Text>
                    <Text style={styles.tableCellReduction}>0</Text>
                    <Text style={styles.tableCellReduction}>0</Text>
                    <Text style={styles.tableCell}>
                      {numeral(
                        PRIMES_ANNUELLES_IAC.find(
                          (prime) =>
                            prime.name === risque.liste_garanties_risques?.name
                        )?.value || 0
                      ).format()}
                    </Text>
                  </View>
                );
              }
            );
          })}

          <View style={styles.tableRow}>
            <Text
              style={[
                styles.tableCellGarantie,
                { fontWeight: "ultrabold", textAlign: "left" },
              ]}
            >
              Sous-Total Individuelle Chauffeur
            </Text>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}>
              {numeral(
                PRIMES_ANNUELLES_IAC.reduce(
                  (acc, prime) => acc + prime.value,
                  0
                )
              ).format()}
            </Text>
            <Text style={styles.tableCell}>
              {numeral(
                PRIMES_ANNUELLES_IAC.reduce(
                  (acc, prime) => acc + prime.value,
                  0
                )
              ).format()}
            </Text>
            <Text style={styles.tableCellReduction}></Text>
            <Text style={styles.tableCellReduction}></Text>
            <Text style={styles.tableCellReduction}></Text>
            <Text style={styles.tableCell}>
              {numeral(
                PRIMES_ANNUELLES_IAC.reduce(
                  (acc, prime) => acc + prime.value,
                  0
                )
              ).format()}
            </Text>
          </View>

          {souscription.souscription_garanties.some((garantie) =>
            GARANTIES_ASSISTANCE_ENUMS.includes(
              garantie.company_has_liste_garanties
                .pool_directives_tarifaires_has_listes_garanties.liste_garanties
                .name
            )
          ) && (
            <>
              <GarantieSectionHeader title="Assistance" />

              {souscription.souscription_garanties.map(
                (souscription_garantie) => {
                  if (
                    !GARANTIES_ASSISTANCE_ENUMS.includes(
                      souscription_garantie.company_has_liste_garanties
                        .pool_directives_tarifaires_has_listes_garanties
                        .liste_garanties.name
                    )
                  )
                    return null;

                  return (
                    <View style={styles.tableRow} key={souscription_garantie.uuid}>
                      <Text
                        style={[styles.tableCellGarantie, { paddingLeft: 8 }]}
                      >
                        {
                          souscription_garantie.company_has_liste_garanties
                            .pool_directives_tarifaires_has_listes_garanties
                            .liste_garanties.name
                        }
                      </Text>
                      <Text style={styles.tableCell}>Voir annexe</Text>
                      <Text style={styles.tableCell}>Voir annexe</Text>
                      <Text style={styles.tableCell}></Text>
                      <Text style={styles.tableCell}>
                        {numeral(
                          souscription_garantie.souscription_garanties_risques.reduce(
                            (acc, risque) => acc + Number(risque.value),
                            0
                          ) || 0
                        ).format()}
                      </Text>
                      <Text style={styles.tableCellReduction}>0</Text>
                      <Text style={styles.tableCellReduction}>0</Text>
                      <Text style={styles.tableCellReduction}>0</Text>
                      <Text style={styles.tableCell}>
                        {numeral(
                          souscription_garantie.souscription_garanties_risques.reduce(
                            (acc, risque) => acc + Number(risque.value),
                            0
                          ) || 0
                        ).format()}
                      </Text>
                    </View>
                  );
                }
              )}

              <View style={styles.tableRow}>
                <Text
                  style={[
                    styles.tableCellGarantie,
                    { fontWeight: "ultrabold", textAlign: "left" },
                  ]}
                >
                  Sous-Total Assistance
                </Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}>
                  {numeral(
                    souscription.souscription_garanties
                      .filter((garantie) =>
                        GARANTIES_ASSISTANCE_ENUMS.includes(
                          garantie.company_has_liste_garanties
                            .pool_directives_tarifaires_has_listes_garanties
                            .liste_garanties.name
                        )
                      )
                      .reduce(
                        (total, garantie) =>
                          total +
                          garantie.souscription_garanties_risques.reduce(
                            (acc, risque) => acc + Number(risque.value),
                            0
                          ),
                        0
                      )
                  ).format()}
                </Text>
                <Text style={styles.tableCellReduction}></Text>
                <Text style={styles.tableCellReduction}></Text>
                <Text style={styles.tableCellReduction}></Text>
                <Text style={styles.tableCell}>
                  {numeral(
                    souscription.souscription_garanties
                      .filter((garantie) =>
                        GARANTIES_ASSISTANCE_ENUMS.includes(
                          garantie.company_has_liste_garanties
                            .pool_directives_tarifaires_has_listes_garanties
                            .liste_garanties.name
                        )
                      )
                      .reduce(
                        (total, garantie) =>
                          total +
                          garantie.souscription_garanties_risques.reduce(
                            (acc, risque) => acc + Number(risque.value),
                            0
                          ),
                        0
                      )
                  ).format()}
                </Text>
              </View>
            </>
          )}

          {/* Additional rows can be added similarly */}
        </View>
      </View>
    </>
  );
}

export default ConditionsParticulieresGaranties;

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.2, // Fine external borders
    borderColor: "#000",
    fontSize: 7,
  },
  tableRow: {
    flexDirection: "row",
    fontSize: 7,
  },
  tableCell: {
    borderStyle: "solid",
    borderWidth: 0.2,
    borderBottomStyle: "dotted",
    borderColor: "#000",
    padding: 2,
    width: "12%", // Réduire légèrement la largeur des autres colonnes
    fontSize: 7,
    textAlign: "right",
  },
  tableCellGarantie: {
    borderStyle: "solid",
    borderWidth: 0.2,
    borderBottomStyle: "dotted",
    borderColor: "#000",
    padding: 2,
    width: "24%", // Augmenter la largeur de la colonne des garanties
    fontSize: 7,
    textAlign: "left", // Aligner le texte à gauche pour une meilleure lisibilité
  },
  tableCellReduction: {
    borderStyle: "solid",
    borderWidth: 0.2,
    borderBottomStyle: "dotted", // Internal dotted lines
    borderColor: "#000",
    padding: 2,
    width: "5%",
    fontSize: 7,
    textAlign: "right",
  },
  headerCell: {
    fontWeight: "bold",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  reductionMajorationHeader: {
    borderStyle: "solid",
    borderWidth: 0.2,
    borderBottomStyle: "solid", // Keep solid for headers
    borderColor: "#000",
    padding: 2,
    width: "15%", // Spans the 3 sub-columns
    textAlign: "center",
    fontWeight: "bold",
  },
});
